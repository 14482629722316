/* Style Scrollbar */
.appartementen ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.appartementen ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.appartementen ::-webkit-scrollbar-thumb {
  background: #0e5b49;
}

/* Handle on hover */
.appartementen ::-webkit-scrollbar-thumb:hover {
  background: #0e5b49;
}
/* Mobile menu animation */
/* Mobile menu animation */
.mobile-menu .mobile-nav,
.top-banner {
  animation: fadeIn 0.7s ease-in-out;
  -webkit-animation: fadeIn 0.7s;
  -moz-animation: fadeIn 0.7s;
  -o-animation: fadeIn 0.7s;
  -ms-animation: fadeIn 07s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Gallery Carousel Carousel */

.gallery .control-dots {
  display: flex;
  flex-direction: column;
}

.gallery .carousel .control-dots {
  top: 45%;
  left: 14%;
}

.gallery .dot {
  height: 20px !important;
  width: 20px !important;
  margin-bottom: 10px !important;
}

.react-multi-carousel-dot-list {
  left: 22% !important;
  display: block !important;
  top: 50% !important;
  right: initial !important;
}

@media (max-width: 4000px) and (min-width: 2130px) {
  .react-multi-carousel-dot-list {
    left: 21% !important;
  }
}
@media (max-width: 1023px) and (min-width: 400px) {
  .react-multi-carousel-dot-list {
    left: 16% !important;
  }
}
@media (max-width: 399px) and (min-width: 320px) {
  .react-multi-carousel-dot-list {
    left: 22% !important;
  }
}
.react-multi-carousel-dot--active button {
  background: #fffff6 !important;
  border: 1px solid #fff !important;
}

.react-multi-carousel-dot button {
  border-color: grey;
  background: grey;
}
