/* tailwind configurations */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* font family */
/* @font-face {
  font-family: greycliff-cf;
  src: url(../assets/fonts/greycliff-cf/greycliff-cfCF-Regular.woff);
} */
@import url("https://use.typekit.net/bxr8ixo.css");


/* css files imports */
@import url("./layout.css");
@import url("./homepage.css");

body {
  margin: 0;
  padding: 0;
  font-family: "greycliff-cf", sans-serif;
}

/* nav active class */
.active {
  font-weight: bolder;
  font-family: "greycliff-cf", sans-serif;
}

/* Checkbox style */

#checkbox {
  width: 13px;
  height: 13px;
  cursor: pointer;
  background: transparent;
  color: black;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  position: relative;
  left: -5px;
  top: 1px;
  border: 1px solid white;
}

#checkbox:checked {
  background: white;
}

.h-70{
  height: 70px;
}